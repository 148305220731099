import React from 'react';
import { sleep } from 'utils';
import { useStore } from '../../store';
import Api from '../../api';

export default function Preload() {
  const { userStore, settingsStore } = useStore();

  React.useEffect(() => {
    (async () => {
      const tryFetchUser = async (settings: any) => {
        try {
          const user = await Api.fetchUser();
          return user;
        } catch (err) {
          if (settings['SSO.enabled']) {
            if (err.status === 401) {
              window.location.href = `${settings['reader.url']}?action=login`;
            } else if (err.status === 403) {
              window.location.href = `${settings['reader.url']}/permissionDeny`;
            }
            // 跳转需要一点时间，加一个等待，防止闪屏
            await sleep(5000);
          }
        }
        return null;
      };

      const tryFetchSettings = async () => {
        try {
          const settings = await Api.fetchSettings();
          return settings;
        } catch (err) {}
        return {};
      };

      const settings = await tryFetchSettings();
      const user = await tryFetchUser(settings);
      if (user) {
        userStore.setUser(user);
      }
      settingsStore.setSettings(settings);
      document.title = settings['site.title'];
      userStore.setIsFetched(true);
      settingsStore.setIsFetched(true);
    })();
  }, [userStore, settingsStore]);

  return null;
}
