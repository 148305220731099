import React from 'react';
import { computed } from 'mobx';
import { useHistory } from 'react-router-dom';
import { observer, useLocalStore, useComputed } from 'mobx-react-lite';
import { Input, Button, CircularProgress, IconButton } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { useStore } from '../../store';
import Api from '../../api';

import './PhoneLogin.scss';

interface Props {
  onBack: () => unknown;
}

export const PhoneLogin = observer((props: Props) => {
  const history = useHistory();
  const state = useLocalStore(() => ({
    phone: '',
    code: '',
    loading: false,
    sendCodeLoading: false,
    countdown: 0,
  }));

  const phoneValidComputed = computed(() => /^1\d{10}$/.test(state.phone));
  const phoneValid = useComputed(() => phoneValidComputed.get());
  const formValid = useComputed(() => phoneValidComputed.get() && !!state.code);

  const { userStore, settingsStore, snackbarStore } = useStore();

  const handleSendCode = () => {
    if (state.countdown) {
      return;
    }
    state.sendCodeLoading = true;
    Api.getXuePhoneCode(state.phone)
      .then(
        () => {
          snackbarStore.show({
            message: '验证码发送成功',
            duration: 2000,
          });

          state.countdown = 120;
          const id = window.setInterval(() => {
            state.countdown -= 1;
            if (!state.countdown) {
              window.clearInterval(id);
            }
          }, 1000);
        },
        () => {
          snackbarStore.show({
            message: '验证码发送失败',
            type: 'error',
            duration: 2000,
          });
        },
      )
      .finally(() => {
        state.sendCodeLoading = false;
      });
  };

  const handleFormSubmit = async () => {
    if (!formValid) {
      return;
    }
    state.loading = true;
    let verifySuccess = false;
    await Api.verifyXuePhoneCode(state.phone, state.code)
      .then(
        () => {
          verifySuccess = true;
        },
        (err: Error) => {
          snackbarStore.show({
            message: `登录失败（${err.message}）`,
            type: 'error',
            duration: 2000,
          });
        },
      )
      .finally(() => {
        state.loading = false;
      });

    if (!verifySuccess) {
      return;
    }

    Api.fetchUser().then(
      (user) => {
        userStore.setUser(user);
        history.push('/dashbord');
      },
      () => {
        snackbarStore.show({
          message: '登录失败',
          type: 'error',
          duration: 2000,
        });
      },
    );
  };

  return (
    <div className="login-container phone-login relative flex flex-col justify-center items-center mx-auto bg-white p-10 ex-width-300 rounded">
      <div className="back-btn-box mt-2 ml-2 absolute top-0 left-0">
        <IconButton size="medium" onClick={props.onBack}>
          <KeyboardArrowLeft />
        </IconButton>
      </div>

      <div className="text-center text-56">
        <img
          src="https://static.press.one/pub/logo_cn.png"
          width="120"
          height="107"
          alt="fly-pub"
        />
      </div>
      <div className="mt-2 text-center text-gray-700 text-lg font-bold">
        {settingsStore.settings['site.shortTitle']}
      </div>
      <div className="hr ex-width-90 mx-auto mt-5 border-b"></div>
      <div className="dark-color text-center mt-5 font-bold">XUE.cn 手机号登录</div>
      <div className="login-form flex flex-col mt-2">
        <Input
          className="mt-5"
          placeholder="手机号 +86"
          value={state.phone}
          onChange={(e) => {
            state.phone = e.target.value;
          }}
          inputProps={{
            maxLength: 11,
          }}
          error={false}
        />
        <div className="relative flex">
          <Input
            className="mt-2 flex-grow"
            placeholder="验证码"
            inputProps={{ maxLength: 6 }}
            value={state.code}
            onChange={(e) => {
              state.code = e.target.value;
            }}
            error={false}
          />
          <div className="absolute send-code right-0 bottom-0">
            <Button
              size="small"
              disabled={!phoneValid || !!state.countdown || state.sendCodeLoading}
              onClick={handleSendCode}
            >
              {!state.sendCodeLoading && (
                <span>{state.countdown ? `${state.countdown} 秒后重新发送` : '发送验证码'}</span>
              )}
              {state.sendCodeLoading && (
                <CircularProgress className="absolute" size={16} thickness={5} />
              )}
            </Button>
          </div>
        </div>
        <div className="mt-8 flex justify-center">
          <Button
            className="primary"
            variant="contained"
            onClick={handleFormSubmit}
            disabled={!formValid || state.loading}
          >
            登录{state.loading && <span>...</span>}
          </Button>
        </div>
      </div>
    </div>
  );
});
