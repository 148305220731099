import React from 'react';
import classNames from 'classnames';

export default (props: any) => {
  const { onClick, className, outline } = props;

  return (
    <button
      className={classNames(
        {
          'border border-blue-400 text-blue-400': outline,
          'text-white bg-blue-400': !outline,
          [className]: className,
        },
        `py-2 px-4 rounded font-bold text-sm outline-none)`,
      )}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="flex justify-center items-center">{props.children}</div>
    </button>
  );
};
