import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'components/Button';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => unknown;
  onOk: () => unknown;
  type: 'allow' | 'deny';
  userName: string;
}

export const renderConfirmDialog = (props: ConfirmDialogProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle id="responsive-dialog-title">
      <span className="block pt-4">修改权限</span>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <span className="block text-center px-2">
          确定{{ allow: '允许', deny: '禁止' }[props.type]} <strong>{props.userName}</strong>{' '}
          发布文章吗？
          <br />
          {props.type === 'deny' && (
            <span className="block text-sm">（已发布文章在阅读站将不可见）</span>
          )}
        </span>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <span className="block text-gray-700 cursor-pointer px-2 mr-4" onClick={props.onClose}>
        取消
      </span>
      <span className="block pr-4">
        <Button onClick={props.onOk}>确定</Button>
      </span>
    </DialogActions>
    <span className="block pb-4" />
  </Dialog>
);
