export const getMarkdownCheatSheet = () => `
### 标题
\`\`\`
# 一级标题
## 二级标题
### 三级标题
#### 四级标题
##### 五级标题
\`\`\`
### 斜体和粗体
\`\`\`
*斜体*
**粗体**
\`\`\`
### 无序列表
\`\`\`
* 苹果
* 橘子
\`\`\`
\`\`\`
- 苹果
- 橘子
\`\`\`
### 有序列表
\`\`\`
1. 苹果
2. 橘子
\`\`\`
### 代码
\`\`\`
    四个空格缩进
    产生一个代码块
\`\`\`
\`\`\`\`
\`\`\`
代码
\`\`\`
\`\`\`\`
### 图片
\`\`\`
![自学是门手艺](https://cdn.xue.cn/hub/static/app/static/screensnap.8d874585.png)
\`\`\`
### 链接
\`\`\`
[xue.cn](https://xue.cn)
\`\`\`
### 分隔符
\`\`\`
---
\`\`\`
### 引用
\`\`\`
> 人所拥有的任何东西都有可能被剥夺，唯独人性最后的自由——也就是在任何境遇中选择一己态度和生活方式的自由——不能被剥夺 -- 弗兰克尔
\`\`\`
### 表格
\`\`\`
| 姓名 | 年龄 |
| ---------------- | ---------------- |
| 张三 | 22 |
| 李四 | 28 |
\`\`\`
\`\`\`
姓名 | 年龄
--- | ---
张三 | 22
李四 | 28
\`\`\`
`;
