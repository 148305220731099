import React from 'react';
import { toJS, observable } from 'mobx';
import { createUserStore } from './user';
import { createFilesStore } from './files';
import { createSnackbarStore } from './snackbar';
import { createSettingsStore } from './settings';
import { createWalletStore } from './wallet';

const storeContext = React.createContext<any>(null);

interface IProps {
  children: React.ReactNode;
}

const store = {
  userStore: observable(createUserStore()),
  fileStore: observable(createFilesStore()),
  snackbarStore: observable(createSnackbarStore()),
  settingsStore: observable(createSettingsStore()),
  walletStore: observable(createWalletStore()),
};

type Store = typeof store

export const StoreProvider = ({ children }: IProps) => {
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('You have forgot to use StoreProvider');
  }
  (window as any).toJS = toJS;
  (window as any).store = store;
  return store as Store;
};
