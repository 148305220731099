import React from 'react';
import { reaction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Tooltip } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Loading from '../../components/Loading';
import { Endpoint } from '../../utils';
import { useStore } from '../../store';

import './index.scss';
import { PhoneLogin } from './PhoneLogin';

export default observer((props: any) => {
  const state = useLocalStore(() => ({
    transition: {
      timeout: 0,
      provider: {
        fadeIn: true,
        display: true,
      },
      xuecn: {
        fadeIn: false,
        display: false,
      },
    },
    xueCNLoginPage: false,
  }));

  React.useEffect(() => {
    reaction(
      () => state.xueCNLoginPage,
      () => {
        window.clearTimeout(state.transition.timeout);
        if (state.xueCNLoginPage) {
          state.transition.provider.fadeIn = false;
          state.transition.timeout = window.setTimeout(() => {
            state.transition.provider.display = false;
            state.transition.xuecn.display = true;
            state.transition.xuecn.fadeIn = true;
          }, 300);
        }
        if (!state.xueCNLoginPage) {
          state.transition.xuecn.fadeIn = false;
          state.transition.timeout = window.setTimeout(() => {
            state.transition.xuecn.display = false;
            state.transition.provider.display = true;
            state.transition.provider.fadeIn = true;
          }, 300);
        }
      },
    );
  }, [state]);

  const { userStore, settingsStore } = useStore();
  const { settings } = settingsStore;
  const providers = settings['auth.providers'];
  const logo = settings['site.logo'] || 'https://static.press.one/pub/logo_cn.png';
  const [loadingProvider, setLoadingProvider] = React.useState('');

  const selectProvider = (provider: string) => {
    setLoadingProvider(provider);
  };

  const getLoginUrl = (provider: string) => {
    return `${Endpoint.getApi()}/api/auth/${provider}/login?redirect=${
      window.location.origin
    }/dashboard`;
  };

  if (userStore.isFetched && userStore.isLogin) {
    setTimeout(() => {
      props.history.push('/dashboard');
    }, 0);
  }

  if (!settingsStore.isFetched) {
    return null;
  }

  const getIconUrl = (provider: string) => {
    const iconMap: any = {
      mixin: 'https://static.press.one/pub/mixin.png',
      github: 'https://static.press.one/pub/github.svg',
      pressone: 'https://static.press.one/pub/pressone.png',
    };
    return iconMap[provider];
  };

  const Provider = (props: any) => {
    const { provider } = props;
    if (provider === 'xuecn') {
      return null;
    }
    return (
      <Tooltip placement="top" title={`使用 ${provider} 账号登录`}>
        <a
          href={getLoginUrl(provider)}
          onClick={() => selectProvider(provider)}
          className={`${provider} login-btn rounded-full flex justify-center items-center border mx-2`}
        >
          {loadingProvider !== provider && <img src={getIconUrl(provider)} alt={provider} />}
          {loadingProvider === provider && <Loading size={20} />}
        </a>
      </Tooltip>
    );
  };

  return (
    <>
      <Fade in={true} timeout={1000}>
        <div className="login bg flex justify-center items-center">
          {state.transition.provider.display && (
            <Fade in={state.transition.provider.fadeIn} timeout={300}>
              <div className="login-container flex flex-col justify-center items-center mx-auto bg-white p-10 ex-width-300 rounded">
                <div className="text-center text-56">
                  <img src={logo} width="100" height="100" alt="fly-pub" />
                </div>
                <div className="mt-4 text-center text-gray-700 text-lg font-bold">
                  {settingsStore.settings['site.shortTitle']}
                </div>
                <div className="mt-5 text-gray-600 text-center w-40 text-sm">
                  {settingsStore.settings['site.slogan']}
                </div>
                <div className="hr ex-width-90 mx-auto mt-5 border-b"></div>
                {providers.length > 0 && (
                  <div>
                    <div className="dark-color text-center mt-5">第三方账号登录</div>
                    <div className="flex justify-center items-center mt-5">
                      {providers.map((provider: string) => (
                        <div key={provider}>
                          <Provider provider={provider} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {providers.includes('xuecn') && (
                  <div className="text-center mt-5">
                    <a
                      href="#/"
                      onClick={() => {
                        state.xueCNLoginPage = true;
                      }}
                      className="green-color font-bold"
                    >
                      XUE.cn 手机号登录
                    </a>
                  </div>
                )}
              </div>
            </Fade>
          )}
          {state.transition.xuecn.display && (
            <Fade in={state.transition.xuecn.fadeIn} timeout={300}>
              <div>
                <PhoneLogin
                  onBack={() => {
                    state.xueCNLoginPage = false;
                  }}
                />
              </div>
            </Fade>
          )}
        </div>
      </Fade>
    </>
  );
});
