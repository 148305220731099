import React from 'react';
import { observer } from 'mobx-react-lite';
import { RouteChildrenProps, Route } from 'react-router';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode';
import Help from '@material-ui/icons/Help';
import Fade from '@material-ui/core/Fade';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Button from 'components/Button';

import { useStore } from '../../store';

import { Endpoint, getQuery, removeQuery, sleep } from '../../utils';

import './index.scss';
import Dashboard from '../Dashboard';
import Topic from '../Topic';
import PostManager from '../PostManager';
import WalletModal from 'components/WalletModal';

export default observer((props: RouteChildrenProps) => {
  const { userStore, snackbarStore, settingsStore } = useStore();
  const { user } = userStore;
  const { settings } = settingsStore;
  const [walletOpen, setWalletOpen] = React.useState(false);
  const [walletTab, setWalletTab] = React.useState('assets');
  const action = getQuery('action');

  React.useEffect(() => {
    (async () => {
      if (action === 'OPEN_WALLET_BINDING') {
        await sleep(1500);
        setWalletTab('mixinAccount');
        setWalletOpen(true);
        if (userStore.user.mixinAccount) {
          await sleep(800);
          snackbarStore.show({
            message: 'Mixin 账号绑定成功啦！',
          });
        }
        removeQuery('action');
      }
    })();
  }, [action, snackbarStore, userStore]);

  if (userStore.isFetched && !userStore.isLogin) {
    setTimeout(() => {
      props.history.push('/login');
    }, 0);
  }

  const logout = () => {
    window.location.href = `${Endpoint.getApi()}/api/logout?from=${window.location.origin}/login`;
  };

  const navList = [
    {
      text: '文章',
      icon: CreateIcon,
      path: '/dashboard',
      show: true,
    },
    {
      text: '【管理后台】用户权限',
      icon: AccountCircle,
      path: '/topic',
      show: user.isAdmin,
    },
    {
      text: '【管理后台】文章权限',
      icon: AssignmentIcon,
      path: '/postManager',
      show: user.isAdmin,
    },
  ];

  return (
    <Fade in={true} timeout={500}>
      <div className="p-manage-layout flex">
        <nav className="p-manage-layout-nav flex flex-col justify-between border-r relative">
          <section
            className={classNames(
              {
                invisible: !settingsStore.isFetched,
              },
              'pt-4 w-full',
            )}
          >
            {!settingsStore.settings['SSO.enabled'] && (
              <div>
                <div className="px-4 flex items-center">
                  <div className="w-10 h-10">
                    <img
                      className="w-10 h-10"
                      src="https://xue-images.pek3b.qingstor.com/1124-logo.png"
                      alt="logo"
                    />
                  </div>
                  {settings['site.shortTitle'] && (
                    <span className="text-base font-bold ml-4 text-gray-700 flex items-center">
                      {settings['site.shortTitle']}
                      <Tooltip
                        placement="right"
                        title="我们帮你把文章发布到区块链，之后会有不同的阅读站点把你的文章呈现给读者，这是去中心化的模式，一键发布，多端阅读"
                      >
                        <Help className="ml-1 text-gray-600" />
                      </Tooltip>
                    </span>
                  )}
                </div>
                <div className="mt-8 mx-2 pl-8 flex items-center leading-none">
                  <a
                    href={settingsStore.settings['reader.url']}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tooltip
                      placement="right"
                      title="阅读站可以有很多个，是去中心化的，这只是其中一个。你的文章将会被不同的阅读站聚合，给千千万万的读者阅读并打赏"
                    >
                      <div className="text-xl text-gray-600 py-2 flex items-center">
                        <ChromeReaderMode />
                        <span className="text-sm ml-2">阅读站</span>
                      </div>
                    </Tooltip>
                  </a>
                </div>
              </div>
            )}
            {settingsStore.settings['SSO.enabled'] && (
              <div className="px-4 flex items-center">
                <div className="w-10 h-10">
                  <img
                    className="w-10 h-10"
                    src="https://xue-images.pek3b.qingstor.com/1124-logo.png"
                    alt="logo"
                  />
                </div>
                <div className="text-base font-bold ml-4 text-gray-700 flex items-center justify-center tracking-wide">
                  创作文章
                </div>
              </div>
            )}
            <ul className="p-manage-layout-nav-ul">
              <li className="px-5 mt-8 p-manage-layout-nav-ul-title p-manage-layout-nav-li text-sm text-gray-700 font-bold">
                管理
              </li>
              {navList
                .filter((v) => v.show)
                .map((item) => (
                  <li key={item.text} className="p-manage-layout-nav-li">
                    <div
                      onClick={() => props.history.push(item.path)}
                      className={classNames(
                        {
                          'bg-gray-200': props.location.pathname === item.path,
                        },
                        'mx-2 pl-8 flex items-center text-lg text-gray-600 py-3 mt-1 rounded cursor-pointer leading-none',
                      )}
                    >
                      <item.icon />
                      <span className="text-sm ml-2">{item.text}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </section>
          {
            <div className="w-full absolute bottom-0 left-0">
              {userStore.isFetched && (
                <div className="flex items-center flex-col justify-center">
                  <img src={user.avatar} className="w-16 h-16 rounded-full" alt="头像" />
                  <span className="dark-color text-md mt-2">{user.name}</span>
                </div>
              )}
              <div className="mx-2 mb-3 mt-5">
                <Button className="w-full" onClick={() => setWalletOpen(true)}>
                  <div className="flex items-center text-lg mr-1">
                    <AccountBalanceWallet />
                  </div>
                  写作收入
                </Button>
              </div>
              <div className="mx-2 pb-5">
                {settingsStore.settings['SSO.enabled'] && (
                  <a href={settingsStore.settings['reader.url']}>
                    <Button className="w-full" outline>
                      <ArrowBackIos /> 返回首页
                    </Button>
                  </a>
                )}
                {!settingsStore.settings['SSO.enabled'] && (
                  <Button className="w-full" outline onClick={logout}>
                    退出
                  </Button>
                )}
              </div>
            </div>
          }
        </nav>

        <main className="p-manage-layout-main flex flex-col">
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/topic" exact component={Topic} />
          <Route path="/postManager" exact component={PostManager} />
        </main>

        <WalletModal open={walletOpen} onClose={() => setWalletOpen(false)} tab={walletTab} />
      </div>
    </Fade>
  );
});
