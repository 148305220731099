interface ShowParams {
  message?: string,
  duration?: number,
  type?: string,
  meta?: Record<string, any>
}

export function createSnackbarStore() {
  return {
    open: false,
    message: '',
    type: 'default',
    autoHideDuration: 2000,
    meta: {} as Record<string, any>,
    show(options: ShowParams) {
      const {
        message = '',
        duration = 2000,
        type = 'default',
        meta = {},
      } = options;
      this.message = message;
      this.type = type;
      this.autoHideDuration = duration;
      this.open = true;
      this.meta = meta;
    },
    close() {
      this.open = false;
    },
  };
}
