import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'components/Button';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => unknown;
  onOk: () => unknown;
  type: string;
}

export const renderConfirmDialog = (props: ConfirmDialogProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle id="responsive-dialog-title">
      <span className="block pt-2">修改文章权限</span>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <span className="block px-5">
          {props.type === 'deny' && '禁止之后，阅读站将不再显示这篇文章'}
          {props.type === 'allow' && '阅读站将恢复显示这篇文章'}
        </span>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <span className="block text-gray-700 cursor-pointer px-2 mr-4" onClick={props.onClose}>
        取消
      </span>
      <span className="block pr-4">
        <Button onClick={props.onOk}>确定</Button>
      </span>
    </DialogActions>
    <div className="pb-4" />
  </Dialog>
);
